// extracted by mini-css-extract-plugin
module.exports = {"footer":"_3L3wD0q-CE2vE_BUFZ6U3f","container":"_19XM0siN1pit3MFkbtAtPN","linkContainer":"_7yDt82QprwuL3ns-PgE9R","category":"_2PvGYdVEHqPwrTm5kes977","heading":"_1Fz_4CwJ9Uqf0mUVRXBuhw","navLink":"_25Jy0pmDZHNOlPfEGmr-hq","addVoiceLink":"_3FMZQTBwb9KHO_AYDfu2ok","button":"_3m0AIMP5QPOgxsCCAiAoTh","copyright":"_2Mxh7aG0CSx27b3kjRvWWU"};;
    if (module.hot) {
      var injectCss = function injectCss(prev, href) {
        var link = prev.cloneNode();
        link.href = href;
        link.onload = function() {
          prev.parentNode.removeChild(prev);
        };
        prev.stale = true;
        prev.parentNode.insertBefore(link, prev);
      };
      module.hot.dispose(function() {
        window.__webpack_reload_css__ = true;
      });
      if (window.__webpack_reload_css__) {
        module.hot.__webpack_reload_css__ = false;
        console.log("[HMR] Reloading stylesheets...");
        var prefix = document.location.protocol + '//' + document.location.host;
        document
          .querySelectorAll("link[href][rel=stylesheet]")
          .forEach(function(link) {
            if (!link.href.match(prefix) || link.stale) return;
            injectCss(link, link.href.split("?")[0] + "?unix=1744353902250");
          });
      }
    }
  