// extracted by mini-css-extract-plugin
module.exports = {"footer":"_106BAGM8DsCQjmNERo_ZSG","container":"_1X4oT0Orv6HvNN3Q3FFbCb","linkContainer":"_1J75ap2zNaKVNxEjx4ULsU","title":"_1OHyvhnS5VD5udPX5T-76c","heading":"_2X-mj2CnLq0OPtEbX9wbkn","navLink":"_2fBc-Z4N5qRKjmFU9OVMSQ","addVoiceLink":"s9mtzfPlk-6M9AlgjGFyl","button":"_12Msovbd00Cu0-dNwK5KqD","copyright":"_6TB5obhbzqKxy5fKK6gxG","category":"_3kw5f8jxocFKSSqTuYLOOe","addVoice":"_1etLK--YPv9VNe3zgxIXKk"};;
    if (module.hot) {
      var injectCss = function injectCss(prev, href) {
        var link = prev.cloneNode();
        link.href = href;
        link.onload = function() {
          prev.parentNode.removeChild(prev);
        };
        prev.stale = true;
        prev.parentNode.insertBefore(link, prev);
      };
      module.hot.dispose(function() {
        window.__webpack_reload_css__ = true;
      });
      if (window.__webpack_reload_css__) {
        module.hot.__webpack_reload_css__ = false;
        console.log("[HMR] Reloading stylesheets...");
        var prefix = document.location.protocol + '//' + document.location.host;
        document
          .querySelectorAll("link[href][rel=stylesheet]")
          .forEach(function(link) {
            if (!link.href.match(prefix) || link.stale) return;
            injectCss(link, link.href.split("?")[0] + "?unix=1739576734307");
          });
      }
    }
  