// extracted by mini-css-extract-plugin
module.exports = {"addVoice":"_1B5P4P10T_Cckt4Cm8Lirw","menuLogoIcon":"_1TZKAXzZcJk2NdMyhKjk89","mobileNav":"_5C_fQ3hPIebUQ-yn3I6Ek","nav":"_3raYlLKjTM6hLUEv-CCrRV","navbar":"FnG1HpcnXzS9QqB4wE0G9","navbarLogo":"_3ew83ZLox94KfquSi0lDEJ","navLink":"_1pg19TjRT3APkzB_wqGdhm","navMenu":"_2m-YLHC3gZ2iykdsGc7rtz","addVoiceLink":"_380cncTd_uibtW5_hjYicH"};;
    if (module.hot) {
      var injectCss = function injectCss(prev, href) {
        var link = prev.cloneNode();
        link.href = href;
        link.onload = function() {
          prev.parentNode.removeChild(prev);
        };
        prev.stale = true;
        prev.parentNode.insertBefore(link, prev);
      };
      module.hot.dispose(function() {
        window.__webpack_reload_css__ = true;
      });
      if (window.__webpack_reload_css__) {
        module.hot.__webpack_reload_css__ = false;
        console.log("[HMR] Reloading stylesheets...");
        var prefix = document.location.protocol + '//' + document.location.host;
        document
          .querySelectorAll("link[href][rel=stylesheet]")
          .forEach(function(link) {
            if (!link.href.match(prefix) || link.stale) return;
            injectCss(link, link.href.split("?")[0] + "?unix=1734172984955");
          });
      }
    }
  